import React from 'react';
import GatsbyImage from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import aboutBanner from '../images/about_banner.svg';

export default () => {
    const data = useStaticQuery(graphql`
    query {
      topImage: file(relativePath: { eq: "landing-grid-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bottomImage: file(relativePath: { eq: "deshazos.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`);
    return (
        <Layout pageTitle="About us" ogUrl="/about/">
          <div className='big-layout-heading'>
              <div style={{backgroundImage: `url(${aboutBanner})`}} className='content-container'>
                  <h3>Welcome to the till family</h3>
                  <p>At till, we firmly believe the most valuable piece of furniture in our home is our dining room table. We’re here to conveniently provide you with local food worth conversing about. We’d love to invite you to learn why below.</p>
              </div>
              <div className='svg-container'>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 20" width="100%" height="100%">
                      <path fill='currentColor' d="M0 0C0.33 0.13 0.53 0.21 0.62 0.24C32.55 12.93 68.13 12.84 100 0C100 0 100 0 100 0L100 20L0 20L0 0Z" />
                  </svg>
              </div>
          </div>
            <div className='layout-container'>
                <div className='layout-page-section'>
                    <h3 className='margin-top-4 margin-bottom-2'>We built till to deeply connect local farmers to you, our Birmingham community</h3>
                    <div className='image-container'>
                        <GatsbyImage fluid={data.topImage.childImageSharp.fluid} />
                    </div>
                    <p>Our city is a culinary gem. Birmingham plays home to several of the best restaurants in the South. These top restaurants source the majority of their menu items from farms within a 150-mile radius of our city and every top chef has access to local farmers 365 days a year. As a result, they create food worth conversing about.</p>
                    <p>We (Hayley and Will, foodies and founders of till here) adore cooking with and sharing great food. Some of our fondest, early memories of dating revolve around meeting at the farmers market early on a Saturday morning. We’d get there around 7am to ensure our favorite vendors weren’t out of produce and, well, usually by 11am it’s 95 degrees and our iced-coffee has turned into ice-water.</p>
                    <p>Around the brunch table one day an idea came to us- “What if farmers could reach us without having to sweat at the Saturday market, and what if we, the consumer, could reach the farmers conveniently from the comfort of our homes? What if the farmers market came to our door?” Turns out, we weren’t the only ones with this desire.</p>
                    <h3 className='margin-top-4 margin-bottom-2'>Using till enables you to support local, conveniently</h3>
                    <p>As life-long natives, Birmingham has always been home to us. With a passion for food and small business, we have created till around you- because you shouldn’t have to sacrifice local-spending and healthy eating due purely to a lack of access and convenience. Why can’t you have the same 365-day access to local ingredients as our top chefs do?</p>
                    <p><strong>Now you can.</strong></p>
                    <p>At till, we till through Alabama’s top, artisan farmers and allow you to shop their freshest selections online year-round. We then deliver your custom made order directly to your door.</p>
                    <p><strong>Supporting local just got a whole lot easier.</strong></p>
                    <div className='image-container' style={{maxWidth: 450}}>
                        <GatsbyImage fluid={data.bottomImage.childImageSharp.fluid} />
                    </div>
                    <p style={{marginBottom: 0, fontWeight: 'bold'}}>{`Will & Hayley deShazo`}</p>
                    <p>Founders of till</p>
                </div>
            </div>
        </Layout>
    )
}